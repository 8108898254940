import {useState, useEffect} from "react"

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import { faker } from '@faker-js/faker';
import {
  
  Label,
} from "reactstrap"
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Grid,
  TablePagination,
} from '@mui/material';
import DataTable from "react-data-table-component"
import {useDispatch,useSelector} from 'react-redux'
import { faHome,faEye,faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../sections/@dashboard/app';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { requestAxios, requestAxios2 } from "../../common/common";
import {SHOW_MODAL} from '../../store/cocan/actionTypes'


export default function User() {
    const navigate = useNavigate();
    const [search,setSearch] = useState("")
    const dispatch = useDispatch();
  const storeData = useSelector(state => state.cocan)
  const [visibility, setVisibility]= useState("none")
  const[rows,setRows]= useState([])
  const[rowsInit,setRowsInit]= useState([])
  const [count, setCount] = useState(23)
  const[data,setDatas]= useState([])

  const filterData=(v)=>{
    setSearch(v)
    let mx =[]
    if(v.length>0){
       mx = rowsInit.filter((x)=>x.name.toLowerCase().includes(v) || x.phone.toLowerCase().includes(v) || x.email.toLowerCase().includes(v))
    }
    else{
       mx = rowsInit
    }
    setRows(mx)

    setCount(count*rows.length)
  }
  const refresh = async(uuid) =>{
    try{
        dispatch({type : SHOW_MODAL,data:{showModal:1}});
        
        const req = await requestAxios2({},`test-mail/${uuid}`)
        console.log(req)
        if(req.status === 200){
            console.log(req)
            alert(req.data.message)
        }
        dispatch({type : SHOW_MODAL,data:{showModal:0}});
      }
      catch(e){
        dispatch({type : SHOW_MODAL,data:{showModal:0}});

      }
  }

  const init = async() =>{
    try{
        dispatch({type : SHOW_MODAL,data:{showModal:1}});
        
        const req = await requestAxios({},"subscriber/list")
        console.log(req)
        if(req.status === 200){
            console.log(req)

            setRows(req.data.data)
            setRowsInit(req.data.data)
        }
        dispatch({type : SHOW_MODAL,data:{showModal:0}});
      }
      catch(e){
        dispatch({type : SHOW_MODAL,data:{showModal:0}});

      }
  }
  const init2 = async() =>{
    try{
        dispatch({type : SHOW_MODAL,data:{showModal:1}});
        
        const req = await requestAxios({},"subscriber/all")
        console.log(req)
        if(req.status === 200){
            console.log(req)

            setDatas(req.data.data)
        }
        dispatch({type : SHOW_MODAL,data:{showModal:0}});
      }
      catch(e){
        dispatch({type : SHOW_MODAL,data:{showModal:0}});

      }
  }
  useEffect(() => {
    init()
    init2()
  },[])
  

    useEffect(() => {
    
        if(storeData.showModal===1){
          setVisibility("block")
        }
        else{
          setVisibility("none")
        }
      },[storeData.showModal])
      const tableColumns = [
        {
          name: 'Actions',
          button: true,
          
          cell: (row)  => (
              <div>
                 <span>
                 <FontAwesomeIcon icon={faEye} color={"black"} style={{fontSize:"15px",cursor:"pointer"}} onClick={()=>{navigate(`/fr/admin/details/${row.uuid}`, { replace: true });}} />
                     
                  </span> 
                  <span style={{paddingLeft:"10px"}}>
                 <FontAwesomeIcon icon={faEnvelope} color={"black"} style={{fontSize:"15px",cursor:"pointer"}} onClick={()=>{refresh(`${row.uuid}`)}} />
                     
                  </span> 
                  </div>
          )
      },
        
        {
            name: 'Désignation',
            selector: 'name',
            center:true,
        },
        {
            name: 'Email',
            selector: 'email',
            center:true,
            
        },
        {
            name: 'Contact',
            selector: 'phone',
            center:true,
            
        },
        {
          name: 'Eglise',
          selector: 'ncc',
          center:true,
          
      },
     
        
      ]

  return (
    <Page title="Inscriptions">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Inscriptions
          </Typography>
          
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Tickets 10.000 XOF" total={data.length>0?data.filter((x)=>x.price === 10000)[0].nbr:0} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Tickets 30.000 XOF" total={data.length>0?data.filter((x)=>x.price === 30000)[0].nbr:0} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Tickets 50.000 XOF" total={data.length>0?data.filter((x)=>x.price === 50000)[0].nbr:0} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Tickets 100.000 XOF" total={data.length>0?data.filter((x)=>x.price === 100000)[0].nbr:0} color="error" icon={'ant-design:bug-filled'} />
          </Grid>

         



        </Grid>
        <br/>
        <br/>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Label htmlFor={`name`} >Rechercher </Label>
                        </div>
        <input className="form-control"
                          type="text"
                          id={`search`}
                          name={`search`}
                          onChange={(v) => { 
                            filterData(v.target.value)

                           }}
                          value={search}
                          defaultValue={search}
                        />
                        <br/>
        <Card>
          

          <Scrollbar>
          <DataTable
                        columns={tableColumns}
                        data={rows}
                        striped={"true"}
                        center={"true"}
                        persistTableHead
                       />
          </Scrollbar>

          
        </Card>
        <div style={{zIndex:99999,backgroundColor:'rgb(47,52,48,0.6)',width:"100%",height:"100%",display:visibility, position:"fixed",top:0,left:0}}> </div>
      </Container>
    </Page>
  );
}
