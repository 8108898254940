import {useState, useEffect} from "react"
import { Link as RouterLink } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux'

// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
import {ToastContainer,toast,Slide} from 'react-toastify'

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import logo from "../assets/img/logo.png";
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

 const Login=()=> {
  const dispatch = useDispatch();
  const storeData = useSelector(state => state.cocan)
  useEffect(() => {
    
    if(storeData.showModal===1){
      setVisibility("block")
    }
    else{
      setVisibility("none")
    }
  },[storeData.showModal])
  const smUp = useResponsive('up', 'sm');
  const [visibility, setVisibility]= useState("none")

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Connexion">
      <RootStyle>
        <HeaderStyle>
          
          <img src={logo} alt="Jubilé" style={{width:"150px"}}/>

          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Vous n'avez pas de compte? {''}
             
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Bienvenue
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
               Jubilé de gloire 
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Entrez vos infos.</Typography>


            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Jubilé de gloire  {' '}
                
              </Typography>
            )}
          </ContentStyle>
          <div style={{zIndex:99999,backgroundColor:'rgb(47,52,48,0.6)',width:"100%",height:"100%",display:visibility, position:"fixed",top:0,left:0}}> </div>
          <ToastContainer/>

        </Container>
      </RootStyle>
    </Page>
  );
}
export default Login;