import {useState, useEffect} from "react"

import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {useDispatch,useSelector} from 'react-redux'
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {ToastContainer,toast,Slide} from 'react-toastify'

// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import {requestAxios} from "../../../common/common";
import {SHOW_MODAL} from '../../../store/cocan/actionTypes';

export default function LoginForm() {
  const dispatch = useDispatch();
  const storeData = useSelector(state => state.cocan)

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (e) => {
    dispatch({type : SHOW_MODAL,data:{showModal:1}});

    e.preventDefault()
    const phone = document.getElementById("phone").value;
    const pwd  = document.getElementById("password").value

    try{
      const data ={

        phone,
        pwd
      }
      const req = await requestAxios(data,"login")
      console.log(req)
      if(req.status === 200){
        localStorage.setItem("authUser",JSON.stringify(req.data.data))
        navigate('/fr/admin', { replace: true });
      }
      dispatch({type : SHOW_MODAL,data:{showModal:0}});
  
    }
    catch(e){
      dispatch({type : SHOW_MODAL,data:{showModal:0}});

    }
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3}>
        <RHFTextField name="phone"  id="phone" label="Contact" type="number" />

        <RHFTextField
          name="password"
          id="password"
          label="Mot de passe"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Se souvenir de moi" />
        <Link variant="subtitle2" underline="hover" style={{display:"none"}}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
      <ToastContainer/>

    </FormProvider>
  );
}
