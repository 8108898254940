import React,{ useState, useContext, Fragment } from 'react'
import { toast, Slide } from 'react-toastify'

import axios from "axios"
import {host} from "./index"



const ToastContent = ({ title, description }) => (
  <div>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <h6 className='toast-title font-weight-bold'>{title}</h6>
      </div>
    </div>
    <div className='toastify-body'>
      <span>{description}</span>
    </div>
  </div>
)
const showToast = ({ title, description, isSuccess }) => {

  if (isSuccess === false) {
    toast.error(
      <ToastContent title={title} description={description} />,
      { transition: Slide, hideProgressBar: true, autoClose: 2000 }
    )
  } else {
    toast.success(
      <ToastContent title={title} description={description} />,
      { transition: Slide, hideProgressBar: true, autoClose: 2000 }
    )
  }
}
export const requestAxios = async(data, url) => {

  let mx = null
  let token = ""
  try {
    const userData = JSON.parse(localStorage.getItem("authUser"));
    
    token = userData.token
  } catch (e) {
    console.log(e)
  }
  await axios({
      url :`${host}${url}`,
      method:"POST",
      headers:{
        Authorization:token !== null ? token : ""
      },
      data
  }).then(res => {
      mx = (res)
    
  }).catch(error => {
    mx = {status : 400}
    
    if (error.response) {
      mx = error.response
      alert(error.response.data.message)
      const newUrl  = (`${host}${url}`)

      if (mx.status === 409 && newUrl.includes("api/v1/client") === true) {
        localStorage.removeItem("cod_auth")
        window.location.href = "/fr/connexion"
      } 
    } else if (error.request) {
      mx = {status:400}
      toast.error(
        <ToastContent title={"Erreur"} description={"Impossible d'accéder au serveur"} />,
        { transition: Slide, hideProgressBar: true, autoClose: 2000 }
      )
    } else {
      toast.error(
        <ToastContent title={"Erreur"} description={error.message} />,
        { transition: Slide, hideProgressBar: true, autoClose: 2000 }
      )
    }
  })
  return mx
}
export const requestAxios2 = async(data, url) => {

  let mx = null
  let token = ""
  try {
    const userData = JSON.parse(localStorage.getItem("authUser"));
    
    token = userData.token
  } catch (e) {
    console.log(e)
  }
  await axios({
      url :`${host}${url}`,
      method:"GET",
      headers:{
        Authorization:token !== null ? token : ""
      },
      data
  }).then(res => {
      mx = (res)
    
  }).catch(error => {
    mx = {status : 400}
    
    if (error.response) {
      mx = error.response
      alert(error.response.data.message)
      const newUrl  = (`${host}${url}`)

      if (mx.status === 409 && newUrl.includes("api/v1/client") === true) {
        localStorage.removeItem("cod_auth")
        window.location.href = "/fr/connexion"
      } 
    } else if (error.request) {
      mx = {status:400}
      toast.error(
        <ToastContent title={"Erreur"} description={"Impossible d'accéder au serveur"} />,
        { transition: Slide, hideProgressBar: true, autoClose: 2000 }
      )
    } else {
      toast.error(
        <ToastContent title={"Erreur"} description={error.message} />,
        { transition: Slide, hideProgressBar: true, autoClose: 2000 }
      )
    }
  })
  return mx
}