import { combineReducers } from "redux"

import cocan from "./cocan/reducer"

const rootReducer = combineReducers({
 
  cocan
})

export default rootReducer
