import {useState, useEffect} from "react"
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
import StepContent from '@mui/material/StepContent';
import { LoadingButton } from '@mui/lab';
import DataTable from "react-data-table-component"
import {useDispatch,useSelector} from 'react-redux'

import {
  Row,
  Col,
  Card as Cards,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input
} from "reactstrap"
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import success from "../../assets/success.png";
import orange from "../../assets/om.jpg";
import mtn from "../../assets/mtn.jpg";
// sections
import { RegisterForm } from '../../sections/auth/register';
import { requestAxios } from "../../common/common";
import {SHOW_MODAL} from '../../store/cocan/actionTypes'
import ms from "../../assets/img/ms.png"
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));
const steps = [
    'Etape 1 : Identification',
    'Etape 2 : Liste des particpants',
    'Etape 3 : Mode de paiement',
    "Etape 4 : Récapitulatif",
  ];

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: "85%",
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const dispatch = useDispatch();
  const storeData = useSelector(state => state.cocan)
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  

  return (
    <Page title="Success">
      <RootStyle>
       <div style={{width:"100%",height:"100%"}}>
        <img src={ms} alt="ms" style={{width:"100%",height:"calc(100vh)"}}/>
        <div style={{width:"100%",height:"calc(100vh)",top:0,left:0, position:"fixed",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div style={{display:"flex",justifyContent:"center", alignItems:"center",flexDirection:"column",backgroundColor:"white",padding:"30px", minWidth:"450px",borderRadius:"20px"}}>
           <Typography variant="h4" gutterBottom>
            Jubilé de gloire 
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Inscription validée </Typography>
            <img src={success} alt={"success"} style={{width:"250px"}}/>
           
           </div>
        
        </div>
       </div>
      </RootStyle>
    </Page>
  );
}
