import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Participate from "./pages/participate/index"
import Success from "./pages/participate/success"
import Inscription from "./pages/admin/inscription"
import Details from "./pages/admin/details"
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/fr/admin',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Inscription /> },
        { path: 'app', element: <Inscription /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'inscriptions', element: <Inscription /> },
        { path: 'details/:uuid', element: <Details /> },

      ],
    },
    
    
    {
      path: 'fr/details/:uuid',
      element: <Details />,
    },
    
    {
      path: 'fr/login',
      element: <Login />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: 'fr/inscription',
      element: <Participate />,
    },
    {
      path: 'fr/validation',
      element: <Success />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/fr/inscription" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
