import {useState, useEffect} from "react"

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink,useParams } from 'react-router-dom';

import { faHome,faUserEdit,faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Label,
} from "reactstrap"
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import DataTable from "react-data-table-component"
import {useDispatch,useSelector} from 'react-redux'
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { requestAxios, requestAxios2 } from "../../common/common";
import {SHOW_MODAL} from '../../store/cocan/actionTypes'



export default function User() {
    const dispatch = useDispatch();
  const storeData = useSelector(state => state.cocan)
  const [email, setEmail]= useState("")
  const [oldEmail, setOldEmail]= useState("")
  const [uuidCompany, setuuidCompany]= useState("")
  const [uuidEmail, setUuidEmail]= useState("")
  const [visibility, setVisibility]= useState("none")
  const [visibilityEdit, setVisibilityEdit]= useState("none")
  const[rows,setRows]= useState([])
  const { uuid } = useParams();
  

  const init = async(uuid) =>{
    try{
        dispatch({type : SHOW_MODAL,data:{showModal:1}});
        
        const req = await requestAxios({},`subscriber/details/${uuid}`)
        console.log(req)
        if(req.status === 200){
            console.log(req)

            setRows(req.data.data)
        }
        dispatch({type : SHOW_MODAL,data:{showModal:0}});
      }
      catch(e){
        dispatch({type : SHOW_MODAL,data:{showModal:0}});

      }
  }
  const refresh = async() =>{
    try{
        dispatch({type : SHOW_MODAL,data:{showModal:1}});
        
        const req = await requestAxios2({},`test-mail/${uuidCompany}`)
        console.log(req)
        if(req.status === 200){
            console.log(req)
            alert(req.data.message)
        }
        dispatch({type : SHOW_MODAL,data:{showModal:0}});
      }
      catch(e){
        dispatch({type : SHOW_MODAL,data:{showModal:0}});

      }
  }
  const save = async() =>{
    try{
        dispatch({type : SHOW_MODAL,data:{showModal:1}});
        const req = await requestAxios2({},`subscriber/edit/${uuidEmail}/${email}`)
        console.log(req)
        if(req.status === 200){
          setVisibilityEdit("none")
          init(uuidCompany)
        }
        dispatch({type : SHOW_MODAL,data:{showModal:0}});
      }
      catch(e){
        dispatch({type : SHOW_MODAL,data:{showModal:0}});

      }
  }
  useEffect(() => {
    setuuidCompany(uuid)
    init(uuid)
    
  },[])
  

    useEffect(() => {
    
        if(storeData.showModal===1){
          setVisibility("block")
        }
        else{
          setVisibility("none")
        }
      },[storeData.showModal])
      const tableColumns = [
        {
          name: 'N°',
          selector: 'id',
          center:true,
          width:"50px",
        },
        
        {
            name: 'Nom & Prénoms',
            selector: 'name',
            center:true,
        },
        {
            name: 'Email',
            selector: 'email',
            center:true,
            
        },
        {
            name: 'Contact',
            selector: 'phone',
            center:true,
            
        },
        
        {
            name: 'Prix',
            selector: 'price',
            center:true,
            
        },{
          name: 'Qrcode',
          selector: 'qrcode',
          center:true,
          cell: (row)  => (
            <div>
              {`${row.qrcode.substring(0,6)}******`}
            </div>
        )
      },
        
        {
            name: 'Actions',
            button: true,
            
            cell: (row)  => (
                <div>
                 <span>
                 <FontAwesomeIcon icon={faUserEdit} color={"black"} style={{fontSize:"15px",cursor:"pointer"}} onClick={()=>{setVisibilityEdit("flex");setUuidEmail(row.uuid);setOldEmail(row.email);setEmail("")}} />
                     
                  </span>   
                </div>
            )
        },
      ]

  return (
    <Page title="Participants">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Participants
          </Typography>
          
        </Stack>

        <Card>
          

          <Scrollbar>
          <DataTable
                        columns={tableColumns}
                        data={rows}
                        striped={"true"}
                        center={"true"}
                        persistTableHead
                       />
                        <div style={{width:"100%",flexDirection:"row",display:"flex",marginTop:"10px",justifyContent:"end"}}>
                         
                         <Button color="success" type="button" style={{ width: "150px", cursor: "pointer", marginLeft:"10px", color: "white" }} onClick={() => { refresh() }}>Renvoyer les tickets</Button>
                         </div>
          </Scrollbar>

          
        </Card>
        <div style={{zIndex:99999,backgroundColor:'rgb(47,52,48,0.6)',width:"100%",height:"100%",display:visibility, position:"fixed",top:0,left:0}}> </div>
        <div style={{zIndex:99999,backgroundColor:'rgb(47,52,48,0.6)',width:"100%",height:"100%",display:visibilityEdit, position:"fixed",top:0,left:0,justifyContent:"center",alignItems:"center"}}> 
        <div style={{width:"400px", backgroundColor:"white",borderRadius:"8px",padding:"10px",display:"flex",flexDirection:"column"}}>
          Modification email
          <br/>
          <Label htmlFor={`name`} >Ancien mail </Label>
          <Label htmlFor={`name`} style={{fontWeight:"bold"}}>{oldEmail}</Label>

          <Label htmlFor={`name`} >Nouveau mail </Label>
        <input className="form-control"
                          type="text"
                          id={`email`}
                          name={`email`}
                          onChange={(v) => { 
                            setEmail(v.target.value)
                           }}
                          value={email}
                          defaultValue={email}
                        />
                         <div style={{width:"100%",flexDirection:"row",display:"flex",marginTop:"10px",justifyContent:"end"}}>
                         <Button color="success" type="button" style={{ width: "150px", cursor: "pointer", backgroundColor: "white", color: "black" }} onClick={() => { setVisibilityEdit("none") }}>Fermer</Button>
                         
                         <Button color="success" type="button" style={{ width: "150px", cursor: "pointer", marginLeft:"10px", color: "white" }} onClick={() => { save() }}>Valider</Button>
                         </div>
                        </div>
        </div>
      </Container>
    </Page>
  );
}
