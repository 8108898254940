import { useState, useEffect } from "react"
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
import StepContent from '@mui/material/StepContent';
import { LoadingButton } from '@mui/lab';
import DataTable from "react-data-table-component"
import { useDispatch, useSelector } from 'react-redux'
import { faHome, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  Row,
  Col,
  Card as Cards,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input
} from "reactstrap"
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import logo from "../../assets/img/logo.png";
import moov from "../../assets/moov.png";
import orange from "../../assets/om.jpg";
import mtn from "../../assets/mtn.jpg";
// sections
import { RegisterForm } from '../../sections/auth/register';
import { requestAxios } from "../../common/common";
import { SHOW_MODAL } from '../../store/cocan/actionTypes'
import ms from "../../assets/img/ms.png"

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));
const steps = [
  'Etape 1 : Identification',
  'Etape 2 : Particpants à l\'evenement',
  'Etape 3 : Récapitulatif',
  'Etape 4 : Métode de paiement',
];

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: "85%",
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const dispatch = useDispatch();
  const storeData = useSelector(state => state.cocan)
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  const [activeStep, setActiveStep] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [typeVisio, setTypeVisio] = useState(1)
  const [type, setType] = useState(1)
  const [rows, setRows] = useState([])
  const [count, setCount] = useState(1)
  const [namePerson, setNamePerson] = useState("")
  const [rulePerson, setRulePerson] = useState("")
  const [emailPerson, setEmailPerson] = useState("")
  const [phonePerson, setPhonePerson] = useState("")
  const [nameInt, setNameInt] = useState("")
  const [phoneInt, setPhoneInt] = useState("")
  const [emailInt, setEmailInt] = useState("")
  const [church, setChurch] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [rule, setRule] = useState("")
  const [format, setFormat] = useState("")
  const [district, setDistrict] = useState("")
  const [situation, setSituation] = useState("")
  const [fixe, setFixe] = useState("")
  const [capital, setCapital] = useState("")
  const [domain, setDomain] = useState("Informatique")
  const [principalDomain, setPrincipalDomain] = useState("")
  const [ncc, setNcc] = useState("")
  const [commerce, setCommerce] = useState("")
  const [isMM, setIsMM] = useState(true)
  const [isCard, setIsCard] = useState(false)
  const [isOm, setIsOm] = useState(false)
  const [isCheckedPhys, setIsCheckedPhys] = useState(false)
  const [isCheckedMoral, setIsCheckedMoral] = useState(true)
  const [visibility, setVisibility] = useState("none")
  const [visiblePopup, setVisiblePopup] = useState("flex")
  const options = [
    { value: 'Culture, loisirs et transport', label: 'Culture, loisirs et transport' },
    { value: 'Industries', label: 'Industries' },
    { value: 'Maison et travaux', label: 'Maison et travaux' },
    { value: 'Sante et bien être', label: 'Sante et bien être' },
    { value: ' Entreprises', label: 'Entreprises' },
    { value: 'Finances', label: 'Finances' },
    { value: 'Associations professionnelles', label: 'Associations professionnelles' },
    { value: 'Emploi', label: 'Emploi' },
    { value: 'Formations , éducation', label: 'Formations , éducation' },
    { value: 'Import et export', label: 'Import et export' },
    { value: 'Sécurité', label: 'Sécurité' },
    { value: 'Service', label: 'Service' },
    { value: 'Service', label: 'Service' },
    { value: 'Transports', label: 'Transports' },
    { value: 'Urgence', label: 'Urgence' },
    { value: 'Communication, publicitaire', label: 'Communication, publicitaire' },
    { value: 'Bâtiment et construction', label: 'Bâtiment et construction' },
    { value: 'Administrations', label: 'Administrations' },
    { value: 'Commerce', label: 'Sante et bien être' },
    { value: 'Télécommunication', label: 'Télécommunication' },
    { value: 'Automobile et moto', label: 'Sante et bien être' },
    { value: 'Immobilier', label: 'Immobilier' },
    { value: 'Comptabilité , juriste et conseil', label: 'Comptabilité , juriste et conseil' },
    { value: 'Tourisme et loisirs', label: 'Tourisme et loisirs' },
    { value: 'Informatique ,internet', label: 'Informatique ,internet' },
    { value: 'Sante', label: 'Sante' },
    { value: 'Alimentation', label: 'Alimentation' },
    { value: 'Artisans', label: 'Artisans' },
    { value: 'Agroalimentaire', label: 'Agroalimentaire' },
    { value: 'Bien être', label: 'Bien être' },
    { value: 'Autres', label: 'Autres' },

  ]
  const juridique = [
    { value: "Mr", label: "Un homme" },
    { value: "Mme", label: "Une femme" },

  ]


  const [number, setNumber] = useState(1)
  const [nameUser1, setNameUser1] = useState("")
  const [phoneUser1, setPhoneUser1] = useState("")
  const [emailUser1, setEmailUser1] = useState("")
  const [priceTicket1, setPriceTicket1] = useState(0)
  const [nameUser2, setNameUser2] = useState("")
  const [phoneUser2, setPhoneUser2] = useState("")
  const [emailUser2, setEmailUser2] = useState("")
  const [priceTicket2, setPriceTicket2] = useState(0)
  const [nameUser3, setNameUser3] = useState("")
  const [phoneUser3, setPhoneUser3] = useState("")
  const [emailUser3, setEmailUser3] = useState("")
  const [priceTicket3, setPriceTicket3] = useState(0)
  const [nameUser4, setNameUser4] = useState("")
  const [phoneUser4, setPhoneUser4] = useState("")
  const [emailUser4, setEmailUser4] = useState("")
  const [priceTicket4, setPriceTicket4] = useState(0)
  const [nameUser5, setNameUser5] = useState("")
  const [phoneUser5, setPhoneUser5] = useState("")
  const [emailUser5, setEmailUser5] = useState("")
  const [priceTicket5, setPriceTicket5] = useState(0)
  const [total, setTotal] = useState(0)
  const [typeTickets,setTypeTickets] = useState([])

  const init = async()=>{
    const req = await requestAxios({}, "tickets")
    console.log(req)
    if (req.status === 200) {
      setTypeTickets(req.data.data);
      setPriceTicket1(req.data.data[0])
      setPriceTicket2(req.data.data[0])      
      setPriceTicket3(req.data.data[0])
      setPriceTicket4(req.data.data[0])
      setPriceTicket5(req.data.data[0])
    }
  }

  const next = async () => {

    if (activeStep === 0) {
      if (phone.trim().length < 8 || name.trim().length < 2 || !validateEmail(email) || church.trim().length < 2) {
        alert("veuillez vérifier les infos");
          return
      }
      setActiveStep(activeStep + 1)

    }
    else if (activeStep === 1) {
      setTotal(0)
      const allRows = [];
      if (number === 1 || number === "1") {
        if (phoneUser1.trim().length < 8 || nameUser1.trim().length < 2 || !validateEmail(emailUser1)|| priceTicket1 < 1000) {
          alert("veuillez vérifier les infos");
          return
        }
        allRows.push({ id: 1, name: nameUser1, phone: phoneUser1, email: emailUser1, price: priceTicket1, type: typeVisio });
        setRows(allRows)
        const mx = parseInt(priceTicket1,2) 
        setTotal(mx)
        setActiveStep(activeStep + 1)
      }
      else if (number === 2 || number === "2") {
        if (phoneUser1.trim().length < 8 || nameUser1.trim().length < 2 || !validateEmail(emailUser1) || priceTicket1 < 1000 || phoneUser2.trim().length < 8 || nameUser2.trim().length < 2 || !validateEmail(emailUser2) || priceTicket2 < 1000) {

          alert("veuillez vérifier les infos");
          return
        }
        allRows.push({ id: 1, name: nameUser1, phone: phoneUser1, email: emailUser1, price: priceTicket1, type: typeVisio });
        allRows.push({ id: 2, name: nameUser2, phone: phoneUser2, email: emailUser2, price: priceTicket2, type: typeVisio });
        const mx = parseInt(priceTicket1,2)  + parseInt(priceTicket2,2) 
        setTotal(mx)
        setRows(allRows)
        setActiveStep(activeStep + 1)
      }
      else if (number === 3 || number === "3") {
        if (phoneUser1.trim().length < 8 || nameUser1.trim().length < 2 || !validateEmail(emailUser1) || priceTicket1 < 1000 || phoneUser2.trim().length < 8 || nameUser2.trim().length < 2 || !validateEmail(emailUser2) || priceTicket2 < 1000 || phoneUser3.trim().length < 8 || nameUser3.trim().length < 2 || !validateEmail(emailUser3) || priceTicket3 < 1000) {

          alert("veuillez vérifier les infos");
          return
        }
        allRows.push({ id: 1, name: nameUser1, phone: phoneUser1, email: emailUser1, price: priceTicket1, type: typeVisio });
        allRows.push({ id: 2, name: nameUser2, phone: phoneUser2, email: emailUser2, price: priceTicket2, type: typeVisio });
        allRows.push({ id: 3, name: nameUser3, phone: phoneUser3, email: emailUser3, price: priceTicket3, type: typeVisio });
        const mx = parseInt(priceTicket1,2)  + parseInt(priceTicket2,2)  + parseInt(priceTicket3,2) 
        setTotal(mx)
        setRows(allRows)
        setActiveStep(activeStep + 1)
      }
      else if (number === 4 || number === "4") {
        if (phoneUser1.trim().length < 8 || nameUser1.trim().length < 2 || !validateEmail(emailUser1) || priceTicket1 < 1000 || phoneUser2.trim().length < 8 || nameUser2.trim().length < 2 || !validateEmail(emailUser2) || priceTicket2 < 1000 || phoneUser3.trim().length < 8 || nameUser3.trim().length < 2 || !validateEmail(emailUser3) || priceTicket3 < 1000 || phoneUser4.trim().length < 8 || nameUser4.trim().length < 2 || !validateEmail(emailUser4) || priceTicket4 < 1000) {

          alert("veuillez vérifier les infos");
          return
        }
        allRows.push({ id: 1, name: nameUser1, phone: phoneUser1, email: emailUser1, price: priceTicket1, type: typeVisio });
        allRows.push({ id: 2, name: nameUser2, phone: phoneUser2, email: emailUser2, price: priceTicket2, type: typeVisio });
        allRows.push({ id: 3, name: nameUser3, phone: phoneUser3, email: emailUser3, price: priceTicket3, type: typeVisio });
        allRows.push({ id: 4, name: nameUser4, phone: phoneUser4, email: emailUser4, price: priceTicket4, type: typeVisio });
        const mx = parseInt(priceTicket1,2)  + parseInt(priceTicket2,2)  + parseInt(priceTicket3,2)  + parseInt(priceTicket4,2) 
        setTotal(mx)
        setRows(allRows)
        setActiveStep(activeStep + 1)
      }
      else if (number === 5 || number === "5") {
        if (phoneUser1.trim().length < 8 || nameUser1.trim().length < 2 || !validateEmail(emailUser1) || priceTicket1 < 1000 || phoneUser2.trim().length < 8 || nameUser2.trim().length < 2 || !validateEmail(emailUser2) || priceTicket2 < 1000 || phoneUser3.trim().length < 8 || nameUser3.trim().length < 2 || !validateEmail(emailUser3) || priceTicket3 < 1000 || phoneUser4.trim().length < 8 || nameUser4.trim().length < 2 || !validateEmail(emailUser4) || priceTicket4 < 1000 || phoneUser5.trim().length < 8 || nameUser5.trim().length < 2 || !validateEmail(emailUser5) || priceTicket5 < 1000) {

          alert("veuillez vérifier les infos");
          return
        }
        allRows.push({ id: 1, name: nameUser1, phone: phoneUser1, email: emailUser1, price: priceTicket1, type: typeVisio });
        allRows.push({ id: 2, name: nameUser2, phone: phoneUser2, email: emailUser2, price: priceTicket2, type: typeVisio });
        allRows.push({ id: 3, name: nameUser3, phone: phoneUser3, email: emailUser3, price: priceTicket3, type: typeVisio });
        allRows.push({ id: 4, name: nameUser4, phone: phoneUser4, email: emailUser4, price: priceTicket4, type: typeVisio });
        allRows.push({ id: 4, name: nameUser5, phone: phoneUser5, email: emailUser5, price: priceTicket5, type: typeVisio });
        const mx = parseInt(priceTicket1,2)  + parseInt(priceTicket2,2)  + parseInt(priceTicket3,2)  + parseInt(priceTicket4,2)+ parseInt(priceTicket5,2) 
        setTotal(mx)

        setRows(allRows)
        setActiveStep(activeStep + 1)
      }


    }
    else if (activeStep === 2) {
      setActiveStep(activeStep + 1)
    }
    else {
      try {
        dispatch({ type: SHOW_MODAL, data: { showModal: 1 } });
        const data = {
          name,
          phone,
          email,
          situation,
          district,
          fixe,
          type,
          format,
          domain,
          principalDomain,
          subscribers: rows,
          ncc: church,
          nameInt,
          phoneInt,
          emailInt,
          commerce
        }
        console.log(data)
        const req = await requestAxios(data, "subscriber/create")
        console.log(req)
        if (req.status === 200) {
          window.location.replace(req.data.message);
        }
        dispatch({ type: SHOW_MODAL, data: { showModal: 0 } });
      }
      catch (e) {
        dispatch({ type: SHOW_MODAL, data: { showModal: 0 } });

      }
    }
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const changeType = (v) => {
    setType(v)
  }
  const addRow = () => {
    if (emailPerson.trim().length < 5 || namePerson.trim().length < 2 || phonePerson.trim().length < 8 || rulePerson.trim().length < 2) {
      return;
    }
    if (rows.length >= 3) {
      alert("Le nombre maximum de participant par inscription est de 3")
      return;
    }
    const item = { id: rows.length + 1, name: namePerson, phone: phonePerson, email: emailPerson, price: rulePerson, type: typeVisio }
    const allRows = rows;
    allRows.push(item);
    setRows(allRows);
    setCount(allRows.length * 10);
    setNamePerson("")
    setPhonePerson("")
    setRulePerson("")
    setEmailPerson("")
  }
  useEffect(() => {
    init()
    if (storeData.showModal === 1) {
      setVisibility("block")
    }
    else {
      setVisibility("none")
    }
  }, [storeData.showModal])
  const tableColumns = [
    {
      name: 'N°',
      selector: 'id',
      center: true,
      width: "50px",
    },

    {
      name: 'Nom & Prénoms',
      selector: 'name',
      width: "250px",
      center: true,
    },
    {
      name: 'Prix des tickets',
      selector: 'price',
      width: "150px",
      center: true,

    },
    {
      name: 'Email',
      selector: 'email',
      width: "250px",
      center: true,

    },


    {
      name: 'Actions',
      button: true,

      cell: (row) => (
        <div>
          <span>
            <FontAwesomeIcon icon={faClose} color={"red"} style={{ fontSize: "25px", cursor: "pointer" }} onClick={() => { deleteRow(row.id) }} />

          </span>
        </div>
      )
    },
  ]
  const tableColumnsRecaps = [
    {
      name: 'N°',
      selector: 'id',
      center: true,
      width: "50px",
    },
    {
      name: 'Téléphone',
      selector: 'phone',
      center: true,
      cell: (row) => (
        <div>
          <span>
            {row.phone}
          </span>
        </div>
      )
    },
    {
      name: 'Nom & Prénoms',
      selector: 'name',
      width: "250px",
      center: true,
    },
    {
      name: 'Prix des tickets',
      selector: 'price',
      width: "150px",
      center: true,

    },
    {
      name: 'Email',
      selector: 'email',
      width: "250px",
      center: true,

    },


  ]
  const deleteRow = (id) => {
    const allRows = rows
    let search = null
    for (let i = 0; i < allRows.length; i += 1) {
      if (allRows[i].id === id) {
        search = allRows[i];
      }
    }
    if (search !== null) {
      const index = allRows.indexOf(search);
      allRows.splice(index, 1);
      for (let i = 0; i < allRows.length; i += 1) {
        allRows[i].id = i + 1;
      }
    }
    setRows(allRows)
    setCount(allRows.length * 10)

  }

  return (
    <Page title="Register">
      <RootStyle>
        <HeaderStyle>
          <img src={logo} alt="Cocan" style={{ width: "150px", display: "flex" }} />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Jubilé de gloire 
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Jubilé de gloire  | Inscrivez-vous
            </Typography>

            <img alt="register" src="/static/illustrations/illustration_register.png" />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Inscription au Jubilé de gloire 
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Fournissez-nous vos infos.</Typography>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>

                  </Step>
                ))}
              </Stepper>

            </div>


            {activeStep === 0 ? <div style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>


                <Row>

                  <h4>Identification</h4>
                  <Row className="mt-3">
                    <Col md={12}>
                      <div className="mb-2 mt-2">
                        <Label htmlFor="label">Vous êtes (*)</Label>

                        <Select
                          closeMenuOnSelect={false}
                          options={juridique}
                          id={"format"}
                          onChange={(v) => {

                            setFormat(v.value)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-2 mt-2">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Label htmlFor={`name`} >Nom & Prenoms </Label>
                        </div>
                        <input className="form-control"
                          type="text"
                          id={`nameInt`}
                          name={`nameInt`}
                          onChange={(v) => { setName(v.target.value) }}
                          value={name}
                          defaultValue={name}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-2">
                        <Label htmlFor={`phoneInt`}>Mobile</Label>
                        <input className="form-control"
                          type="number"
                          id={`phoneInt`}
                          name={`phoneInt`}
                          onChange={(v) => { setPhone(v.target.value) }}
                          value={phone}
                          defaultValue={phone}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-2">
                        <Label htmlFor={`emailInt`}>Email</Label>
                        <input className="form-control"
                          type="text"
                          id={`emailInt`}
                          name={`emailInt`}
                          onChange={(v) => { setEmail(v.target.value) }}
                          value={email}
                          defaultValue={email}
                        />
                      </div>
                    </Col>


                    <Col md="12">
                      <div className="mb-2">
                        <Label htmlFor={`church`}>Eglise</Label>
                        <input className="form-control"
                          type="text"
                          id={`church`}
                          name={`church`}
                          onChange={(v) => { setChurch(v.target.value) }}
                          value={church}
                          defaultValue={church}
                        />
                      </div>
                    </Col>
                  </Row>

                </Row>


              </div>
            </div> : null}
            {activeStep === 1 ? <div style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>


                <Row>


                  <Row  >

                    <Col md="12" style={{ display: "none" }}>
                      <div className="mb-2 mt-2">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Label htmlFor={`name`} >Nom & Prenoms </Label>
                        </div>
                        <input className="form-control"
                          type="text"
                          id={`name`}
                          name={`name`}
                          onChange={(v) => { setNamePerson(v.target.value) }}
                          value={namePerson}
                          defaultValue={namePerson}
                        />
                      </div>
                    </Col>
                    <Col md="12" style={{ display: "none" }}>
                      <div className="mb-2">
                        <Label htmlFor={`rule`}>Fonction</Label>
                        <input className="form-control"
                          type="text"
                          id={`rule`}
                          name={`rule`}
                          onChange={(v) => { setRulePerson(v.target.value) }}
                          value={rulePerson}
                          defaultValue={rulePerson}
                        />
                      </div>
                    </Col>
                    <Col md="6" style={{ display: "none" }}>
                      <div className="mb-2">
                        <Label htmlFor={`email`}>Email</Label>
                        <input className="form-control"
                          type="text"
                          id={`email`}
                          name={`email`}
                          onChange={(v) => { setEmailPerson(v.target.value) }}
                          value={emailPerson}
                          defaultValue={emailPerson}
                        />
                      </div>
                    </Col>
                    <Col md="6" style={{ display: "none" }}>
                      <div className="mb-2">
                        <Label htmlFor={`phone`}>Mobile</Label>
                        <input className="form-control"
                          type="number"
                          id={`phone`}
                          name={`phone`}
                          onChange={(v) => { setPhonePerson(v.target.value) }}
                          value={phonePerson}
                          defaultValue={phonePerson}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-2">
                        <Label htmlFor={`number`}>Nombre de participant (max : 5)</Label>
                        <select className="form-control" onChange={(v) => { setNumber(v.target.value) }} value={number}  >
                          <option value={1}>{"1"}</option>
                          <option value={2}>{"2"}</option>
                          <option value={3}>{"3"}</option>
                          <option value={4}>{"4"}</option>
                          <option value={5}>{"5"}</option>
                        </select>
                      </div>
                    </Col>

                    {number >= 1 ? <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser2`}>Participant N°1</Label>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-2">
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Label >Type de tickets</Label>
                          </div>
                          <select className="form-control" onChange={(v) => {
                            setPriceTicket1(v.target.value)
                          }} value={priceTicket1}>
                            {
                              typeTickets.map((x)=>{
                                return <option value={x}>{`${x} XOF`}</option>
                              })
                            }
                          </select>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser1`}>Nom & Prénoms</Label>
                          <input className="form-control"
                            type="text"
                            id={`nameUser1`}
                            name={`nameUser1`}
                            onChange={(v) => { setNameUser1(v.target.value) }}
                            value={nameUser1}
                            defaultValue={nameUser1}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`emailUser1`}>Email</Label>
                          <input className="form-control"
                            type="text"
                            id={`emailUser1`}
                            name={`emailUser1`}
                            onChange={(v) => { setEmailUser1(v.target.value) }}
                            value={emailUser1}
                            defaultValue={emailUser1}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`phoneUser1`}>Mobile</Label>
                          <input className="form-control"
                            type="number"
                            id={`phoneUser1`}
                            name={`phoneUser1`}
                            onChange={(v) => { setPhoneUser1(v.target.value) }}
                            value={phoneUser1}
                            defaultValue={phoneUser1}
                          />
                        </div>
                      </Col>

                    </Row> : null}
                    {number >= 2 ? <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser2`}>Participant N°2</Label>

                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-2">
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Label >Type de tickets</Label>
                          </div>
                          <select className="form-control" onChange={(v) => {
                            setPriceTicket2(v.target.value)
                          }} value={priceTicket2}>
                            {
                              typeTickets.map((x)=>{
                                return <option value={x}>{`${x} XOF`}</option>
                              })
                            }
                          </select>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser2`}>Nom & Prénoms</Label>
                          <input className="form-control"
                            type="text"
                            id={`nameUser2`}
                            name={`nameUser2`}
                            onChange={(v) => { setNameUser2(v.target.value) }}
                            value={nameUser2}
                            defaultValue={nameUser2}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`emailUser2`}>Email</Label>
                          <input className="form-control"
                            type="text"
                            id={`emailUser2`}
                            name={`emailUser2`}
                            onChange={(v) => { setEmailUser2(v.target.value) }}
                            value={emailUser2}
                            defaultValue={emailUser2}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`phoneUser2`}>Mobile</Label>
                          <input className="form-control"
                            type="number"
                            id={`phoneUser2`}
                            name={`phoneUser2`}
                            onChange={(v) => { setPhoneUser2(v.target.value) }}
                            value={phoneUser2}
                            defaultValue={phoneUser2}
                          />
                        </div>
                      </Col>

                    </Row> : null}


                    {number >= 3 ? <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser2`}>Participant N°3</Label>

                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-2">
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Label >Type de tickets</Label>
                          </div>
                          <select className="form-control" onChange={(v) => {
                            setPriceTicket3(v.target.value)
                          }} value={priceTicket3}>
                            {
                              typeTickets.map((x)=>{
                                return <option value={x}>{`${x} XOF`}</option>
                              })
                            }
                          </select>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser3`}>Nom & Prénoms</Label>
                          <input className="form-control"
                            type="text"
                            id={`nameUser3`}
                            name={`nameUser3`}
                            onChange={(v) => { setNameUser3(v.target.value) }}
                            value={nameUser3}
                            defaultValue={nameUser3}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`emailUser3`}>Email</Label>
                          <input className="form-control"
                            type="text"
                            id={`emailUser3`}
                            name={`emailUser3`}
                            onChange={(v) => { setEmailUser3(v.target.value) }}
                            value={emailUser3}
                            defaultValue={emailUser3}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`phoneUser3`}>Mobile</Label>
                          <input className="form-control"
                            type="number"
                            id={`phoneUser3`}
                            name={`phoneUser3`}
                            onChange={(v) => { setPhoneUser3(v.target.value) }}
                            value={phoneUser3}
                            defaultValue={phoneUser3}
                          />
                        </div>
                      </Col>

                    </Row> : null}
                    {number >= 4 ? <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser2`}>Participant N°4</Label>

                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-2">
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Label >Type de tickets</Label>
                          </div>
                          <select className="form-control" onChange={(v) => {
                            setPriceTicket4(v.target.value)
                          }} value={priceTicket4}>
                            {
                              typeTickets.map((x)=>{
                                return <option value={x}>{`${x} XOF`}</option>
                              })
                            }
                          </select>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser4`}>Nom & Prénoms</Label>
                          <input className="form-control"
                            type="text"
                            id={`nameUser4`}
                            name={`nameUser4`}
                            onChange={(v) => { setNameUser4(v.target.value) }}
                            value={nameUser4}
                            defaultValue={nameUser4}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-2">
                          <Label htmlFor={`emailUser4`}>Email</Label>
                          <input className="form-control"
                            type="text"
                            id={`emailUser4`}
                            name={`emailUser4`}
                            onChange={(v) => { setEmailUser4(v.target.value) }}
                            value={emailUser4}
                            defaultValue={emailUser4}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-2">
                          <Label htmlFor={`phoneUser4`}>Mobile</Label>
                          <input className="form-control"
                            type="number"
                            id={`phoneUser4`}
                            name={`phoneUser4`}
                            onChange={(v) => { setPhoneUser4(v.target.value) }}
                            value={phoneUser4}
                            defaultValue={phoneUser4}
                          />
                        </div>
                      </Col>

                    </Row> : null}
                    {number >= 5 ? <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser2`}>Participant N°5</Label>

                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-2">
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Label >Type de tickets</Label>
                          </div>
                          <select className="form-control" onChange={(v) => {
                            setPriceTicket5(v.target.value)
                          }} value={priceTicket5}>
                            {
                              typeTickets.map((x)=>{
                                return <option value={x}>{`${x} XOF`}</option>
                              })
                            }
                          </select>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`nameUser5`}>Nom & Prénoms</Label>
                          <input className="form-control"
                            type="text"
                            id={`nameUser5`}
                            name={`nameUser5`}
                            onChange={(v) => { setNameUser5(v.target.value) }}
                            value={nameUser5}
                            defaultValue={nameUser5}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`emailUser5`}>Email</Label>
                          <input className="form-control"
                            type="text"
                            id={`emailUser5`}
                            name={`emailUser5`}
                            onChange={(v) => { setEmailUser5(v.target.value) }}
                            value={emailUser5}
                            defaultValue={emailUser5}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-2">
                          <Label htmlFor={`phoneUser5`}>Mobile</Label>
                          <input className="form-control"
                            type="number"
                            id={`phoneUser5`}
                            name={`phoneUser5`}
                            onChange={(v) => { setPhoneUser5(v.target.value) }}
                            value={phoneUser5}
                            defaultValue={phoneUser5}
                          />
                        </div>
                      </Col>

                    </Row> : null}
                    <div style={{ width: "100%", display: "none", justifyContent: "end", marginTop: "15px" }}>
                      <Button color="success" type="button" onClick={() => { addRow() }}>
                        Ajouter participant
                      </Button>

                    </div>

                    <div style={{ width: "100%", height: "1px", backgroundColor: "grey", marginTop: "20px", marginBottom: "15px", display: "none" }}> </div>
                    <div style={{ width: "100%", height: "1px", backgroundColor: "grey", marginTop: "20px", marginBottom: "15px", display: "none" }}>
                      <DataTable
                        columns={tableColumns}
                        data={rows}
                        striped={"true"}
                        center={"true"}
                        persistTableHead
                      /></div>
                  </Row>

                </Row>


              </div>
            </div> : null}
            {activeStep === 3 ? <div style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>


                <Row className="mb-4">
                  <h4>Moyen de paiement</h4>
                </Row>
                <Row>
                  <Col md="12" className="mb-4">
                    <div style={{ width: "100%", display: "flex", justifyContent: "start" }}>
                      <input type="checkbox" onClick={() => { setIsMM(true); setIsOm(false); setIsCard(false) }} checked={isMM} /><div >Mobile Money</div>
                    </div>
                  </Col>
                  <Col md="12" className="mb-2">
                    <div style={{ width: "100%", display: "flex", justifyContent: "start" }}>
                      <input type="checkbox" onClick={() => { setIsMM(false); setIsOm(false); setIsCard(true) }} checked={isCard} />Carte de crédit
                    </div>
                  </Col>


                </Row>


              </div>
            </div> : null}
            {activeStep === 2 ? <div style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>

                <Row>
                  <Col md={12}>
                    <h4>Récapitulatif</h4>
                    <h6>Informations d'identification</h6>
                    <br />
                    {type === 1 ? <Row>
                      <span> {format}</span>

                      <span>Raison Sociale : {name}</span>
                      <span>Email : {email}</span>
                      <span>Contact : {phone} / {fixe}</span>
                      <span>Eglise : {church}</span>
                    </Row> : <Row>
                      <span>Raison Sociale : {name}</span>
                      <span>Email : {email}</span>
                      <span>Contact : {phone} / {fixe}</span>
                    </Row>}
                    <div style={{ width: "100%", height: "1px", backgroundColor: "grey", marginTop: "20px", marginBottom: "15px" }}> </div>
                    <h6>Participants</h6>
                    <DataTable
                      columns={tableColumnsRecaps}
                      data={rows}
                      striped={"true"}
                      center={"true"}
                      persistTableHead
                    />
                  </Col>
                  <div style={{ width: "100%", height: "1px", backgroundColor: "grey", marginTop: "20px", marginBottom: "15px" }}> </div>
                 

                </Row>


              </div>
            </div> : null}

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
              <div style={{ width: "100px" }}>
                {activeStep > 0 ? <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => {
                  if (activeStep > 0) { setActiveStep(activeStep - 1) }
                }
                }>
                  Précédent
                </LoadingButton> : null}
              </div>
              <div style={{ width: activeStep === 3 ? "150px" : "100px" }}>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => {
                  console.log("ici")
                }}
                >
                  {activeStep === 3 ? "Procédez au paiement" : "Suivant"}
                </LoadingButton>
              </div>
            </div>




          </ContentStyle>
          <div style={{ zIndex: 99997, backgroundColor: 'rgb(47,52,48,0.6)', width: "100%", height: "100%", display: visibility, position: "fixed", top: 0, left: 0 }}> </div>
          <div style={{ zIndex: 99999, backgroundColor: 'rgb(47,52,48,0.6)', width: "100%", height: "100%", display: visiblePopup, position: "fixed", top: 0, left: 0, padding: "10px 10px 10px 10px" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", position: "fixed", top: 0, left: 0, padding: "10px 10px 10px 10px", justifyContent: "center", alignItems: "center" }}>
              <img src={ms} alt="Mohamed sanogo" />
            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", bottom: 65, position: "fixed" }}>
              <Button color="success" type="button" style={{ width: "150px", cursor: "pointer", backgroundColor: "white", color: "black" }} onClick={() => { setVisiblePopup("none") }}>Accéder</Button>
            </div>
          </div>
        </Container>
      </RootStyle>
    </Page>
  );
}
