import {
  SHOW_MODAL,
} from "./actionTypes"

const INIT_STATE = {
  showModal: 0
}

const Calendar = (state = INIT_STATE, action={}) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.data.showModal,
      }

    default:
      return state
  }
}

export default Calendar
